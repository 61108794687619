import React from 'react';
import { graphql } from 'gatsby';

import Layout from '@/components/Layout';

const Partners = () => (
  <Layout>
    <div style={{ height: `100vh`, minHeight: `750px` }}>
      <iframe
        src="https://tally.so/r/mVLWKl"
        width="100%"
        height="100%"
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
        title="Devenir Partenaire avec La fraise"
      />
    </div>
  </Layout>
);

export default Partners;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["general"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
